:root {
  --background-color: #000000; /* Background */
  --secondary-color: #272727; /* White */
  --text-color: #ffffff; /* Main text color */
  --accent-color: #dfdfdf; /* Dark gray */
  --primary-color: #facc00; /* Accent */
  --hover-color: #d4b500; /* Hover effect color */
}

.explore-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-section {
  background-color: var(--background-color); /* Use the defined background color */
  color: var(--text-color); /* Text inherits white color */
  padding: 0.5rem 3rem; /* Add padding for better spacing */
}

.heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem; /* Add spacing below the heading */
  color: var(--text-color);
}

.description {
  font-size: 1rem;
  line-height: 1.75rem; /* Add line height for better readability */
  color: var(--text-color);
  margin-bottom: 1rem;
}

.explore-button:hover {
  background-color: var(--hover-color);
}

.athletes-section {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 2rem;
}

.content-container {
  max-width: 72rem; /* Equivalent to Tailwind's max-w-6xl */
  margin: 0 auto;
}

.section-heading {
  font-size: 1.5rem; /* Equivalent to Tailwind's text-3xl */
  font-weight: bold;
  /*margin-bottom: 1.5rem;*/
}

.card-container {
  background-color: var(--secondary-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  transition: transform 0.3s ease;
}

.card-container:hover {
  transform: translateY(-5px);
}

.card-image {
  width: 100%;
  height: 16rem; /* Equivalent to h-48 */
  object-fit: cover;
  border-radius: 0.5rem;
}

.card-title {
  font-size: 1.25rem; /* Equivalent to text-xl */
  font-weight: 600;
  margin-top: 0.5rem;
}

.card-details {
  margin-top: 0.25rem;
  color: var(--text-color);
}

.card-summary {
  margin-top: 0.5rem;
  color: var(--text-color);
}

.card-statistics {
  margin-top: 1rem;
}

.card-equity-info {
  margin-top: 1rem;
}

.progress-bar-background {
  width: 100%;
  background-color: var(--accent-color); /* Replaces bg-gray-200 */
  height: 0.5rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

.progress-bar {
  height: 100%;
  background-color: var(--primary-color); /* Replaces bg-orange-500 */
  border-radius: 0.25rem;
}

/* Overlay background for the popup */
.popup-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Equivalent to bg-black bg-opacity-50 */
  transition: opacity 0.3s ease-out;
}

/* Popup container styling */
.popup-container {
  background-color: var(--secondary-color); /* White background */
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 50%;
  max-width: 1024px; /* Equivalent to max-w-4xl */
  animation: slide-in 0.3s ease-out; /* Matches animate-slide-in */
}

/* Slide-in animation for the popup */
@keyframes slide-in {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Close button styling */
.popup-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
}

/* Popup image styling */
.popup-image {
  width: 33%;
  height: 30rem; /* Equivalent to h-64 */
  object-fit: cover;
  border-radius: 0.5rem;
}

/* Title styling */
.popup-title {
  font-size: 1.5rem; /* Equivalent to text-2xl */
  font-weight: 600;
  margin-top: 0.5rem;
  color: var(--text-color);
}

/* Input group styling */
.popup-input-group {
  margin-top: 1rem;
}

.popup-label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
}

/* Details and statistics styling */
.popup-details,
.popup-description {
  margin-top: 0.5rem;
  color: var(--text-color);
}

.popup-statistics {
  margin-top: 1rem;
  color: var(--text-color);
}

.popup-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--accent-color); /* Matches border color */
  border-radius: 0.25rem;
  color: var(--text-color);
  background-color: var(--background-color);
}

/* Primary button styling */
.button-primary {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-primary:hover {
  background-color: var(--hover-color);
}
